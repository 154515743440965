import React, { createContext, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const MVestContext = createContext({
    // formData getters
    mVestData: {
        schemeDetails: {
            // employer_number: "",
            first_name: undefined,
            other_names: undefined,
            birth_date: undefined,
            entry_date: new Date().toLocaleDateString('en-GB').replace('/', '-'),
            // sex: "",
            nationality: 'Ghana',
            mobile_number: undefined,
            momo_number: undefined,
            postal_address: undefined,
            region: undefined,
            id_number: undefined,
            agentCode: undefined,
            loading: false,
            // email_address: "",
            // tin: "",
            paymentModal: false,
        },
        contributionDetails: {
            monthly_contribution: undefined,
            monthly_salary: 10,
            contribution_type: undefined
        },
    },
    // memberFiles: [],
    memberSignatureFilename: '',
    memberIdCardFilename: '',
    memberSignature: null,
    memberIdCard: null,
    beneficiaryListData: [],
    idToRemove: 0,
    idToEdit: 0,
    beneficiaryId: 0,
    hasAcceptedTermsAndCondition: false,
    // formData setters
    updateMVestData: () => {},
    addBeneficiaryToListData: () => {},
    updateBeneficiaryListData: () => {},
    deleteBeneficiary: () => {},
    getBeneficiaryToEdit: () => {},
    setBeneficiaryId: () => {},
    setRemoveId: () => {},
    setEditId: () => {},
    updateObjectInArray: () => {},
    handleFileInput: () => {},
    removeFile:() => {},
    togglePaymentModal: () => {},

    // app state
    activePageIndex: 0,
    navigatePrevious: () => {},
    navigateContinue: () => {},
    navigateTo: () => {},

    // drawer
    isDrawerOpen: false,
    isEditDrawerOpen: false,
    isModalOpen: false,
    isEvenSplit: false,
    paymentModal: false,
    splitEvenly: () => {},
    toggleDrawer: () => {},
    toggleEditDrawer: () => {},
    toggleMaxBeneficiariesModal: () => {},
    acceptTermsAndCondition: () => {},
});

const MVestProvider = ({ children }) => {
    // const [activePageIndex, setActivePageIndex] = useLocalStorage(
    //   "activePageIndex",
    //   0
    // );
    const [activePageIndex, setActivePageIndex] = useLocalStorage('activePageIndex', 0);

    const [mVestData, setMVestData] = useLocalStorage('mVestData', {
        schemeDetails: {
            // employer_number: "",
            first_name: undefined,
            other_names: undefined,
            birth_date: undefined,
            entry_date: new Date()
                .toLocaleDateString('en-GB')
                .split('/')
                .reverse()
                .join('-'),
            // sex: "",
            nationality: 'Ghana',
            mobile_number: undefined,
            momo_number: undefined,
            postal_address: undefined,
            region: undefined,
            id_number: undefined,
            agentCode: undefined,
            loading: false,
            // email_address: "",
            // tin: "",
            paymentModal: false,
        },
        contributionDetails: {
            monthly_contribution: undefined,
            monthly_salary: 10,
            contribution_type: undefined
        },
    });
    const [beneficiaryListData, setBeneficiaryListData] = useLocalStorage('beneficiaryListData', []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEvenSplit, setIsEvenSplit] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [beneficiaryId, setId] = useState(0);
    const [idToRemove, setIdToRemove] = useState(0);
    const [idToEdit, setIdToEdit] = useState(0);
    const [memberSignatureFilename, setMemberSignatureFilename] = useLocalStorage('memberSignatureFilename','');
    const [memberIdCardFilename, setMemberIdCardFilename] = useLocalStorage('memberIdCardFilename','');
    const [ memberIdCard, setMemberIdCard] = useLocalStorage('memberIdCard',null);
    const [ memberSignature, setMemberSignature] = useLocalStorage('memberSignature',null);
    const [
        hasAcceptedTermsAndCondition,
        setHasAcceptedTermsAndCondition,
    ] = useLocalStorage('hasAcceptedTermsAndCondition', false);
    const navigatePrevious = () => {
        setActivePageIndex((prevState) => prevState - 1);
    };
    const navigateContinue = () => {
        setActivePageIndex((prevState) => prevState + 1);
    };
    const navigateTo = (page) => {
        setActivePageIndex(page);
    };
    const toggleDrawer = () => {
        setIsDrawerOpen((prevState) => !prevState);
    };
    const splitEvenly = () => {
        setIsEvenSplit((prevState) => !prevState);
    };
    const toggleEditDrawer = () => {
        setIsEditDrawerOpen((prevState) => !prevState);
    };
    const toggleMaxBeneficiariesModal = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    const acceptTermsAndCondition = () => {
        setHasAcceptedTermsAndCondition((prevState) => !prevState);
    };
    const updateMVestData = (obj, page) => {
        setMVestData((prevData) => ({
            ...prevData,
            [page]: { ...prevData[page], ...obj },
        }));
    };

    const togglePaymentModal = () => { setPaymentModal((prevState) => !prevState); };


    const addBeneficiaryToListData = (obj) => {
        setBeneficiaryListData((prevArray) => [...prevArray, obj]);
    };
    const updateBeneficiaryListData = (arr) => {
        setBeneficiaryListData(arr);
    };
    const updateObjectInArray = (arr, { id, ...updatedObj }) => {
        const index = arr.findIndex((obj) => obj.id === id);
        if (index === -1) {
            setBeneficiaryListData(arr);
        }
        const newArr = [...arr];
        newArr[index] = {
            ...newArr[index],
            ...updatedObj,
        };
        setBeneficiaryListData(newArr);
    };

    const deleteBeneficiary = (id) => {
        setBeneficiaryListData((prevArray) =>
            prevArray.filter((obj) => obj.id !== id)
        );
    };
    const getBeneficiaryToEdit = () => {
        return beneficiaryListData[
            beneficiaryListData.findIndex((obj) => obj.id === idToEdit)
        ];
    };
    const setBeneficiaryId = () => {
        setId((prevId) => prevId + 1);
    };

    const setRemoveId = (idToRemove) => {
        setIdToRemove(idToRemove);
    };

    const setEditId = (idToEdit) => {
        setIdToEdit(idToEdit);
    };




    const handleFileInput = (event, inputType) => {
        const file = event?.target?.files[0];
        if (file) {
            const filename = file?.name;
            const fileType = file?.type;
            // Encode the file data as a base64 string
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileData = reader.result;
                const fileSize = file.size;
                // Now, you can store the file content (string) in localStorage using your custom hook.
                if (inputType === 'memberSignature') {
                    setMemberSignatureFilename(filename);
                    setMemberSignature({data:fileData, size: fileSize, mimeType:fileType });
                } else if (inputType === 'memberIdCard') {
                    setMemberIdCardFilename(filename);
                    setMemberIdCard({data:fileData, size: fileSize, mimeType:fileType });
                }
            };      
        }
    };

    const removeFile = (inputType) => {
        if (inputType === 'memberSignature') {
            setMemberSignatureFilename('');
            setMemberSignature(null);
        } else if (inputType === 'memberIdCard') {
            setMemberIdCardFilename('');
            setMemberIdCard(null);
        }
    };


    return (
        <MVestContext.Provider
            value={{
                mVestData,
                activePageIndex,
                isDrawerOpen,
                beneficiaryListData,
                beneficiaryId,
                isModalOpen,
                isEditDrawerOpen,
                idToRemove,
                idToEdit,
                hasAcceptedTermsAndCondition,
                memberSignatureFilename,
                memberIdCardFilename,
                memberSignature,
                memberIdCard,
                paymentModal,
                togglePaymentModal,
                updateMVestData,
                navigateContinue,
                navigatePrevious,
                navigateTo,
                toggleDrawer,
                addBeneficiaryToListData,
                updateBeneficiaryListData,
                deleteBeneficiary,
                setRemoveId,
                setEditId,
                toggleMaxBeneficiariesModal,
                setBeneficiaryId,
                toggleEditDrawer,
                getBeneficiaryToEdit,
                updateObjectInArray,
                splitEvenly,
                acceptTermsAndCondition,
                handleFileInput,
                removeFile
            }}
        >
            {children}
        </MVestContext.Provider>
    );
};

export { MVestContext, MVestProvider };
