
import React, { useEffect, useState } from "react";
import {downloadFileFromS3, getFilesKeyListFromS3} from "../../server/aws_sdk_s3_file_upload"
import { OmdsIcon } from "@mom-omds/react-design-system-components/dist/generated/components";
import { DEFAULT_VALIDATION_DELAY } from "../../../../../ghana/configs/mvest-configs/config";
import {
  OmFormInputFieldWrapper,
} from "@om/component-library-react";
import OmButton from "../../../../../../../components/forms/OmButton";

const DocsRetrievalSection = () => {
  const [client_id, setClientID] = useState("")
  const handleChange = (e) => {
    const value = e.detail;
    console.log("CLIENT ID", value)
    setClientID(value)
  };
  const [objectList, setObjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const Search = async () =>{
    try {
      console.log("generating list::.>>>.")
      setIsLoading(true)
      const response = await getFilesKeyListFromS3(client_id);
      console.log(response)
    if(response?.length > 0){
      setObjectList(response);
      setIsLoading(false)
    }

    } catch (error) {
      console.error("Error:", error);
    }
  }


  const handleDownloadFile = async (key) => {
    try {
      const fileData = await downloadFileFromS3(key);

      // Create a Blob object from the file data
      const blob = new Blob([fileData], { type: "application/octet-stream" });

      // Create a download URL for the Blob
      const downloadUrl = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = key.split("/").pop(); // Extract filename from the key
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <h6 className="medium no-bottom-margin">Provide Client's Unique ID / Number</h6>
      <OmFormInputFieldWrapper
        label="Client's ID"
        id="client_id"
        name="client_id"
        labelAlwaysShow={true}
        isSensitive
        required
        size="full"
        align="left"
        type="text"
        placeholder="e.g. OMPF/MV/000000000000"
        validationDelay={DEFAULT_VALIDATION_DELAY}
        onPersist={(e) => handleChange(e)}
      />
      
<div style={{ marginTop: '20px'}}>
<OmButton
          slot="left"
          onClick={() => Search()}
          type="primary"
          size="large"
          text={isLoading ? "RETRIEVING...": "RETRIEVE DOCUMENTS"}
          disabled={isLoading}
        />
</div>
    


    <div style={{margin: '100px'}}>
      {objectList.map((file) => (
        <div key={file.key} style={{ maxWidth: '100%', marginTop: '2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' , background: '#fff', boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.14)', padding: '10px'}}>
          <div key={file.key} style={{ display: 'flex', gap: '2px', alignItems: 'center'}}>
          <OmdsIcon slot="suffix-icon" size={24} name="document" className="file-upload-icon"></OmdsIcon>
           <p style={{ color: '#1E1E1E', fontSize: '16px', fontWeight: '500', wordWrap:'break-word'}}>{decodeURIComponent(file.key).split("/").pop()}</p>
          </div>
          <button style={{ backgroundColor: 'none', outline: 'none', border: 'none'}} onClick={() => handleDownloadFile(file?.key)}>  <OmdsIcon slot="suffix-icon" size={24} name="file_download" className="file-upload-icon"></OmdsIcon></button>

        </div>
      ))}
    </div>
    </>
  );
};

export default DocsRetrievalSection;
