import React from 'react';
import './DocsRetrievalPage.scss';
import DocsRetrievalSection from './DocsRetrievalSection';

const DocsRetrievalPage = () => {

    return (
        <div className="mvest-docs-retrieval-page">
            <div className="mvest-docs-retrieval-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                Client Documents Retrieval
                </h5>
                <p>Enter information to retrieve the client's documents submitted at the point of registration.</p>
                <DocsRetrievalSection />
            </div>
        </div>
    );
};

export default DocsRetrievalPage;

