import React, { useContext } from "react";
import { navigate } from "@reach/router";
import { OmApplicationPage,   OmApplicationWizardHeader,   OmIcon,} from "@om/component-library-react";
import ApplicationLayout from "../../../../layouts/ApplicationLayout";
import { MVestProvider } from "../applications/mVest/context/MVestContext";
import DocsRetrievalPage from "../applications/mVest/pages/ClientDocsRetrievalPage/DocsRetrieval";


const RetrievalPage = () => {
  const closeHander = () => {
    if (typeof window !== "undefined") {
      localStorage.clear();
    }
    navigate("/");
  };
  return (
    <MVestProvider>
      <ApplicationLayout>
        <OmApplicationPage>
          <div slot="header">
              <OmApplicationWizardHeader
              helpNumber="0860 665 463"
              className="banner-image"
        >
            <div slot="wizard-title">
              <p>
              MVEST ADMIN
              </p>
              <h5>
                <strong>DOC RETRIEVAL</strong>
              </h5>
            </div>

            <a slot="close-button">
              <OmIcon
                className="close-icon"
                iconName="close"
                onClick={() => closeHander()}
              />
            </a>
          </OmApplicationWizardHeader>
          </div>
          <div slot="content-no-sidebar">
           <DocsRetrievalPage />
          </div>
          <div slot="footer" />
        </OmApplicationPage>
      </ApplicationLayout>
    </MVestProvider>
  );
};

export default RetrievalPage;
