// import axios from "axios";

// export const uploadFileToS3 = async (file) => {
//   try {
//     const formData = new FormData();
//     formData.append("file", file);
//     const response = await axios.post(
//       "/om-api/mvest/upload-to-s3",
//       formData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );

//     return response;
//   } catch (error) {
//     throw new Error(error);
//   } 
// };

// export const uploadMultipleFilesToS3 = async (file1, file2, client_id) => {
//   try {
//     const formData = new FormData();
//     formData.append("files", file1);
//     formData.append("files", file2 )
//     formData.append("client_id", client_id )
    
//     if (process.env.ENVIRONMENT_NAME === "prod"){
//       axios.defaults.baseURL = 'https://goals-api.my.oldmutual.co.za';
//     } else {
//       axios.defaults.baseURL = 'https://goals-qa.digital.omapps.net:8080';
//     }

//     const response = await axios.post(
//       "/om-api/mvest/upload-multiple-files-to-s3",
//       formData
//     );

//     return response
//   } catch (error) {
//     throw new Error(error);
//   }
// };



// export const getFilesKeyListFromS3 = async (client_id) => {
//   try {
//     if (process.env.ENVIRONMENT_NAME === "prod"){
//       axios.defaults.baseURL = 'https://goals-api.my.oldmutual.co.za';
//     } else {
//       axios.defaults.baseURL = 'https://goals-qa.digital.omapps.net:8080';
//     }
//     const response = await axios.get(`/om-api/mvest/retrieve-from-s3/${client_id}`);
//     return response?.data;
//   } catch (error) {
//     throw new Error(error);
//   }
// };


// export const downloadFileFromS3 = async (key) => {
//   try {
//     if (process.env.ENVIRONMENT_NAME === "prod"){
//       axios.defaults.baseURL = 'https://goals-api.my.oldmutual.co.za';
//     } else {
//       axios.defaults.baseURL = 'https://goals-qa.digital.omapps.net:8080';
//     }

//     const response = await axios.get(`/om-api/mvest/download-from-s3/${key}`)
//     return response?.data;
//   } catch (error) {
//     throw new Error(error);
//   }
// };




const BASE_URL = process.env.GATSBY_ENVIRONMENT_NAME === 'prod'
    ? 'https://goals-api.my.oldmutual.co.za'
    : 'https://goals-qa.digital.omapps.net:8080';

export const uploadFileToS3 = async (file) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`${BASE_URL}/om-api/mvest/upload-to-s3`, {
            method: 'POST',
            body: formData,
            headers: { 
                'Origin': 'www.oldmutual.co.za'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to upload file.');
        }

        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const uploadMultipleFilesToS3 = async (file1, file2, client_id) => {
    try {
        const formData = new FormData();
        formData.append('files', file1);
        formData.append('files', file2);
        formData.append('client_id', client_id);

        const response = await fetch(`${BASE_URL}/om-api/mvest/upload-multiple-files-to-s3`, {
            method: 'POST',
            body: formData,
            headers: { 
                'Origin': 'www.oldmutual.co.za'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to upload multiple files.');
        }

        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const getFilesKeyListFromS3 = async (client_id) => {
    try {
        const response = await fetch(`${BASE_URL}/om-api/mvest/retrieve-from-s3/${client_id}`, {
      
            method: 'GET',
            headers: { 
                'Origin': 'www.oldmutual.co.za'
            },
            redirect: 'follow',
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve file key list.');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const downloadFileFromS3 = async (key) => {
    try {
        const response = await fetch(`${BASE_URL}/om-api/mvest/download-from-s3/${key}`,{
            method: 'GET',
            headers: { 
                'Origin': 'www.oldmutual.co.za'
            },
            redirect: 'follow',
        });

        if (!response.ok) {
            throw new Error('Failed to download file.');
        }

        const data = await response.blob();
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

